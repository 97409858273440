/* global google */
import $ from 'jquery'
import { Loader } from '@googlemaps/js-api-loader'
import markerIcons from 'fontawesome-markers'
import Tracking from './../tracking'

export default class siteMap {
  constructor (element) {
    this.$element = $(element)
    this.$map = this.$element.find('.map')
    this.$noMap = this.$element.find('.noMap')
    this.$allowTracking = this.$noMap.find('a.allowTracking')

    this.gooleApiKey = this.$element.data('apiKey')
    this.mapInit = this.$element.data('mapInit')
    this.locationMarker = null
    this.map = null
    this.markers = []

    this.openInfoWindow = null
    Tracking.onTrackingAllowed(this.initializeMap.bind(this))

    this.$allowTracking.on('click', function (event) {
      event.preventDefault()
      this.initializeMap()
    }.bind(this))
  }

  initializeMap () {
    this.$noMap.hide()

    const loader = new Loader({
      apiKey: this.gooleApiKey,
      version: 'weekly',
      libraries: ['places']
    })

    loader.loadCallback(function (error) {
      if (error) {
        this.$map.hide()
        console.warn(error)
      } else {
        this.setupMap()
      }
    }.bind(this))
  }

  setupMap () {
    const mapOptions = Object.assign({}, this.mapInit, {
      clickableIcons: false, // When false, map icons are not clickable. A map icon represents a point of interest, also known as a POI. By default map icons are clickable.
      disableDefaultUI: true, // Enables/disables all default UI buttons. May be overridden individually. Does not disable the keyboard controls, which are separately controlled by the MapOptions.keyboardShortcuts option. Does not disable gesture controls, which are separately controlled by the MapOptions.gestureHandling option.
      mapTypeControl: true,
      fullscreenControl: true,
      zoomControl: true,
      gestureHandling: 'cooperative',
      mapTypeId: google.maps.MapTypeId.ROADMAP
    })

    this.map = new google.maps.Map(this.$map.get(0), mapOptions)

    this.map.addListener('center_changed', function () {
      // console.log({lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng()});
    })

    this.map.addListener('zoom_changed', function () {
      // console.log(this.map.getZoom());
    })

    this.drawMarker()
  }

  clearSites () {
    this.sites = []
  }

  addSite (site) {
    this.sites.push(site)
  }

  clearMarker () {
    for (const marker of this.markers) {
      marker.setMap(null)
    }
    this.markers = []
  }

  zoomToMarker () {
    if (this.markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      for (const marker of this.markers) {
        bounds.extend(marker.getPosition())
      }
      this.map.fitBounds(bounds)
    } else {
      this.map.panTo(this.mapInit.center)
      this.map.setZoom(this.mapInit.zoom)
    }
  }

  removeLocation () {
    if (this.locationMarker) {
      this.locationMarker.setMap(null)
    }
    this.locationMarker = null
  }

  setLocation (lat, lng) {
    this.locationMarker = new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: this.map,
      icon: {
        path: markerIcons.STREET_VIEW,
        scale: 1,
        strokeWeight: 1,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillColor: '#58e',
        fillOpacity: 1,
        zIndex: 100000
      }
    })

    this.map.panTo(new google.maps.LatLng(lat, lng))
    this.map.setZoom(10)
  }

  drawMarker () {
    if (this.map) {
      this.clearMarker()
      if (typeof this.sites === 'object') {
        for (const site of this.sites) {
          const marker = new google.maps.Marker({
            position: { lat: site.lat, lng: site.lng },
            url: '#',
            map: this.map,
            icon: {
              path: markerIcons.MAP_MARKER,
              scale: 1,
              strokeWeight: 1,
              strokeColor: 'black',
              strokeOpacity: 1,
              fillColor: site.color,
              fillOpacity: 1
            }
          })

          const infowindow = new google.maps.InfoWindow({
            content: site.infoContent
          })

          marker.addListener('click', function () {
            if (this.openInfoWindow) {
              this.openInfoWindow.close()
              this.openInfoWindow = null
            }
            if (infowindow.content) {
              infowindow.open({
                anchor: marker,
                map: this.map,
                shouldFocus: false
              })
              this.openInfoWindow = infowindow
            }
          }.bind(this))

          this.markers.push(marker)
        }
        this.zoomToMarker()
      }
    }
  }
}
