import $ from 'jquery'
import { getDistance } from 'geolib'
import Behaviors from './behaviors'

export default class siteFilter {
  constructor ($siteListContainer, $filterWrapper, $filterForm, $map, $noResult) {
    this.$siteList = $siteListContainer
    this.$filterWrapper = $filterWrapper
    this.$searchForm = $filterForm
    this.$map = $map
    this.$noResult = $noResult

    this.$noResult.hide()
    this.$siteCards = this.$siteList.find('.site')

    this.filter = {
      query: '',
      testTyp: '',
      position: {
        active: false,
        lat: 0,
        lng: 0
      }
    }

    // create Array of dom elements...
    this.allSites = []
    this.$siteCards.each(function (index, card) {
      const $card = $(card)
      const searchText = $card.data('filterData')
      const searchTestTyp = $card.data('filterTestTypes')
      const geoCoords = $card.data('geoCoords')
      let $contentCardSide = $card.find('.flip-card-back')
      if ($contentCardSide.length < 1) {
        $contentCardSide = $card.find('.card-back')
      }
      const infoContent = $contentCardSide.html()
      this.allSites.push({
        orderIndex: index,
        display: true,
        url: $card.data('siteLink'),
        $dom: $card,
        infoContent: infoContent,
        searchText: searchText,
        searchTestTyp: searchTestTyp,
        lat: geoCoords.lat,
        lng: geoCoords.lng,
        distance: 0,
        color: window.getComputedStyle($card.find('.markerColor').get(0)).getPropertyValue('background-color')
      })
    }.bind(this))

    this.$queryField = this.$searchForm.find('#query')
    this.$searchForm.on('submit', this.handleFormSubmit.bind(this))
    this.$queryField.on('keyup', this.handleQueryChange.bind(this))
    this.$queryField.on('change', this.handleQueryChange.bind(this))

    this.$siteCards.on('click', this.handleSiteCardClick.bind(this))

    this.$filterCountryField = this.$searchForm.find('#country')
    this.$filterCountryField.on('change', this.handleCountryChange.bind(this))

    this.$filterTestTypesField = this.$searchForm.find('#testType')
    this.$filterTestTypesField.on('change', this.handleTestTypesChange.bind(this))

    this.$filterRegionField = this.$searchForm.find('#region')
    this.$filterRegionField.on('change', this.handleRegionChange.bind(this))

    this.$findNearButton = this.$searchForm.find('.findNear')
    this.$findNearButton.on('click', this.handleFindNear.bind(this))

    // initially filter
    window.setTimeout(function () {
      this.setFilterQuery(this.$queryField.val())
    }.bind(this), 150)
  }

  handleSiteCardClick (event) {
    let $card = $(event.target)
    if (!$card.hasClass('site')) {
      $card = $card.closest('.site')
    }
    const link = $card.data('siteLink')
    if (link) {
      window.location.href = $card.data('siteLink')
    }
  }

  reportError (errorClass) {
    const $error = this.$filterWrapper.find(`.errors .error.${errorClass}`)
    $error.addClass('show')
    window.setTimeout(function () {
      $error.removeClass('show')
    }, 5000)
  }

  // eslint-disable-next-line no-unused-vars
  handleFindNear (event) {
    const that = this
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          that.setFilterPosition(position.coords.latitude, position.coords.longitude)
        },
        function (geolocationPositionError) {
          switch (geolocationPositionError) {
            case 1: // PERMISSION_DENIED
              that.reportError('geolocationNotAllowed')
              break
            case 2: // POSITION_UNAVAILABLE
              that.reportError('geolocationNotAvailable')
              break
            default: // TIMEOUT
              that.reportError('geolocationNotAvailable')
              break
          }
        }
      )
    } else {
      that.reportError('geolocationNotAvailable')
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleQueryChange (event) {
    this.setFilterQuery(this.$queryField.val())
  }

  // eslint-disable-next-line no-unused-vars
  handleTestTypesChange (event) {
    this.setFilterTestTyp(this.$filterTestTypesField.val())
  }

  // eslint-disable-next-line no-unused-vars
  handleCountryChange (event) {
    this.$searchForm.submit()
  }

  // eslint-disable-next-line no-unused-vars
  handleRegionChange (event) {
    this.$searchForm.submit()
  }

  // eslint-disable-next-line no-unused-vars
  handleFormSubmit (event) {
    this.setFilterQuery(this.$queryField.val())
    this.$queryField.blur()
  }

  setFilterPosition (lat, lng) {
    if (this.filter.position.active) {
      this.filter.position.active = false
    } else {
      this.filter.position = {
        active: true,
        lat: lat,
        lng: lng
      }
    }
    this.filterSites()
  }

  setFilterQuery (query) {
    this.filter.query = query.toLowerCase().trim()
    this.filterSites()
  }

  setFilterTestTyp (testTyp) {
    this.filter.testTyp = testTyp.trim()
    this.filterSites()
  }

  filterSites () {
    if (this.filter.position.active) {
      this.$findNearButton.addClass('active')
      this.$siteList.addClass('showDistance')
      for (const site of this.allSites) {
        const distance = getDistance({
          latitude: this.filter.position.lat,
          longitude: this.filter.position.lng
        }, { latitude: site.lat, longitude: site.lng })

        const $distance = site.$dom.find('.distance')
        if (site.lat === 0 && site.lng === 0) {
          site.distance = 100000000
          $distance.addClass('notSet')
        } else {
          site.distance = Math.round(distance / 1000)
          $distance.find('span').text(site.distance)
          $distance.removeClass('notSet')
        }
      }
      this.allSites.sort(function (a, b) {
        if (a.distance < b.distance) { return -1 }
        if (a.distance > b.distance) { return 1 }
        if (a.orderIndex < b.orderIndex) { return -1 }
        if (a.orderIndex > b.orderIndex) { return 1 }
        return 0
      })
    } else {
      this.$findNearButton.removeClass('active')
      this.$siteList.removeClass('showDistance')
    }

    for (const site of this.allSites) {
      site.display = false
      if ((this.filter.query === '' || site.searchText.indexOf(this.filter.query) !== -1) &&
                (this.filter.testTyp === '' || $.inArray(this.filter.testTyp, site.searchTestTyp) !== -1)) {
        site.display = true
      }
      site.$dom.detach()
    }

    // ìs there is a map?
    const siteMapBehavior = Behaviors.getBehaviorFromElement(this.$map.get(0))
    let siteMapApi = null
    if (siteMapBehavior) {
      siteMapApi = siteMapBehavior.behavior
    }
    if (siteMapApi) {
      siteMapApi.clearSites(true)
    }

    for (const site of this.allSites) {
      if (site.display) {
        this.$siteList.append(site.$dom)
        if (siteMapApi) {
          if (site.lat !== 0 && site.lng !== 0) {
            siteMapApi.addSite(site)
          }
        }
      }
    }

    if (siteMapApi) {
      siteMapApi.drawMarker()

      if (this.filter.position.active) {
        siteMapApi.setLocation(this.filter.position.lat, this.filter.position.lng)
      } else {
        siteMapApi.removeLocation()
      }
    }
  }
}
