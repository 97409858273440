import $ from 'jquery'

export default class pageFaq {
  constructor (element) {
    this.$faqElements = $('.faqElement', element)
    this.$faqElements.on('click', '.question', this.handleQuestionClick.bind(this))
  }

  handleQuestionClick (event) {
    const targetElement = event.target
    const $target = $(targetElement).closest('.faqElement')

    const $targetContainer = $target.closest('.faqElement')
    if (!$targetContainer.hasClass('open')) {
      $targetContainer.find('.answer').each(function (idx, item) {
        this.expandSection(item, function () {
          $targetContainer.addClass('open')
        })
      }.bind(this))
    } else {
      $targetContainer.find('.answer').each(function (idx, item) {
        this.collapseSection(item, function () {
          $targetContainer.removeClass('open')
        })
      }.bind(this))
    }
  }

  collapseSection (element, callback) {
    var sectionHeight = element.scrollHeight
    var elementTransition = element.style.transition
    element.style.transition = ''
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + 'px'
      element.style.transition = elementTransition
      requestAnimationFrame(function () {
        element.style.height = 0 + 'px'
        callback()
      })
    })
  }

  expandSection (element, callback) {
    var sectionHeight = element.scrollHeight
    element.style.height = sectionHeight + 'px'
    // eslint-disable-next-line no-unused-vars
    const transitionFinished = function (e) {
      element.removeEventListener('transitionend', transitionFinished)
      element.style.height = null
      callback()
    }
    element.addEventListener('transitionend', transitionFinished)
  }
}
