import $ from 'jquery'

export default class countryLanguageSwitch {
  constructor (element) {
    this.$popup = $('.desktopCountryLanguage', element)
    this.$link = $('.languageSwitchLink', element)

    this.$link.on('click', this.handleClick.bind(this))

    $(document).on('click', this.handleDocumentClick.bind(this))
  }

  handleClick () {
    this.$popup.toggleClass('open')
  }

  handleDocumentClick (event) {
    let isElementOfPopup = false
    let isElementOfMenu = false
    let target = event.target
    while (target.parentNode) {
      if (target === this.$popup.get(0)) {
        isElementOfPopup = true
      }
      if (target === this.$link.get(0)) {
        isElementOfMenu = true
      }
      target = target.parentNode
    }

    if (!isElementOfPopup && !isElementOfMenu) {
      this.$popup.removeClass('open')
    }
  }
}
