import $ from 'jquery'
import Behaviors from '../behaviors'

export default class sitesNear {
  constructor (element) {
    this.$element = $(element)
    this.$map = this.$element.find('.siteMap')
    this.$sites = this.$element.find('.siteNear')

    this.geoCoords = this.$element.data('geoCoords')

    // create Array of dom elements...
    this.allSites = []
    this.$sites.each(function (index, card) {
      const $card = $(card)
      const geoCoords = $card.data('geoCoords')
      let $contentCardSide = $card.find('.flip-card-back')
      const infoContent = $contentCardSide.html()
      this.allSites.push({
        orderIndex: index,
        display: true,
        url: $card.data('siteLink'),
        $dom: $card,
        infoContent: infoContent,
        lat: geoCoords.lat,
        lng: geoCoords.lng,
        distance: 0,
        color: window.getComputedStyle($card.find('.markerColor').get(0)).getPropertyValue('background-color')
      })
    }.bind(this))

    this.$sites.on('click', this.handleClick.bind(this))

    this.init()
  }

  handleClick (event) {
    let $card = $(event.target)
    if (!$card.hasClass('siteNear')) {
      $card = $card.closest('.siteNear')
    }
    const link = $card.data('siteLink')
    if (link) {
      window.location.href = $card.data('siteLink')
    }
  }

  init () {
    const siteMapBehavior = Behaviors.getBehaviorFromElement(this.$map.get(0))

    // if not available - try again
    if (!siteMapBehavior) {
      window.setTimeout(() => {
        this.init()
      }, 200)
      return
    }

    let siteMapApi = null
    if (siteMapBehavior) {
      siteMapApi = siteMapBehavior.behavior
    }
    if (siteMapApi) {
      siteMapApi.clearSites(true)
      siteMapApi.addSite({
        orderIndex: 1,
        display: true,
        lat: this.geoCoords.lat,
        lng: this.geoCoords.lng,
        color: '#ffffff'
      })
      for (const site of this.allSites) {
        if (siteMapApi) {
          if (site.lat !== 0 && site.lng !== 0) {
            siteMapApi.addSite(site)
          }
        }
      }
      siteMapApi.drawMarker()
    }
  }
}
