import $ from 'jquery'

export default class autoSubmitHelpSelect {
  constructor (element) {
    this.$form = $(element)
    this.$select = $('select', element)
    this.$select.on('change', this.handleSelectChange.bind(this))
  }

  // eslint-disable-next-line no-unused-vars
  handleSelectChange (event) {
    this.$form.submit()
  }
}
