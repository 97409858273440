import $ from 'jquery'

import tracking from './tracking'

import Behaviors from './behaviors'

$(document).ready(function () {
  Behaviors.attachBehaviors(document)
  tracking.initialize()
})
