/* eslint-disable */

import $ from 'jquery'

export default new class externalModules {
  addNonGDPR () {
    this.googleTagManager()
    this.hubSpot()
    this.metaPixel()
  }

  googleTagManager () {
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({ 'gtm.start':
                new Date().getTime(),
      event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0]
      // eslint-disable-next-line eqeqeq
      var j = d.createElement(s); var dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-NS9SLCT')
  }

  metaPixel(){
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1389427561983621');
    fbq('track', 'PageView');
  }

  hubSpot () {
    $('body').prepend('<script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/9372466.js"></script>')
  }
}()
