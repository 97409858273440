import $ from 'jquery'

export default class navigationBar {
  constructor (element) {
    this.$navigationBar = $(element)
    this.$mobileMenuButton = $('a.mobileMenuButton', element)
    this.$mobileNavigation = $('.mobileNavigation', element)
    this.$mobileMenuButton.on('click', this.handleMenuButtonClick.bind(this))
  }

  handleMenuButtonClick (event) {
    event.preventDefault()

    this.openMenu()
  }

  openMenu () {
    const isOpen = this.$navigationBar.hasClass('mobileOpen')
    if (!isOpen) {
      this.$navigationBar.addClass('mobileOpen')
      this.$mobileNavigation.addClass('mobileOpen')
    } else {
      this.$navigationBar.removeClass('mobileOpen')
      this.$mobileNavigation.removeClass('mobileOpen')
    }
  }
}
