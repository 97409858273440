import $ from 'jquery'
import SiteFilter from '../siteFilter'

export default class pageSiteSelection {
  constructor (element) {
    this.siteFilter = new SiteFilter(
      $('.siteList', element),
      $('.filterWrapper', element),
      $('form.filterForm', element),
      $('.siteMapSection .siteMap', element),
      $('.siteFilterNoResult', element)
    )
  }
}
