import $ from 'jquery'
import Tracking from './../tracking'

export default class youtubePlay {
  constructor (element) {
    this.$element = $(element)
    this.$iframe = $('iframe', element)
    this.$allowTrackingLink = $('.allowTracking', element)
    this.src = this.$iframe.data('youtubeSrc')

    this.$allowTrackingLink.on('click', this.handleAllowTrackingClick.bind(this))
    Tracking.onTrackingAllowed(this.allowYoutube.bind(this))
  }

  handleAllowTrackingClick (event) {
    event.preventDefault()
    this.allowYoutube()
  }

  allowYoutube () {
    this.$element.addClass('hasConsent')
    this.$iframe.attr('src', this.src)
  }
}
